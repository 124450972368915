const COMPANY_NAME = "EXP Portugal";

export const COPY = {
  languages: ["pt", "en"],
  pt: {
    agent: "O seu consultor:",
    agency: "A sua agência:",
    content: {
      title: "CONSIGO A CADA PASSO DO CAMINHO",
      subTitle: "AVALIE O SEU IMÓVEL SEM DEMORA",
    },
    disclaimer: `A utilização desta plataforma é gratuita e pretende fornecer um valor expetável de venda de um imóvel determinado, tendo por base     uma análise estatística. Os resultados gerados são estimativas e fornecem valores meramente indicativos, obtidos a partir da análise estatística de bases de dados de imóveis e outros indicadores de mercado imobiliário. Nessa medida, a ${COMPANY_NAME}, não garante qualquer tipo de compensação caso não sejam alcançados os     valores estimados. Existem outros aspectos determinantes para o apuramento do valor económico de um imóvel pelo que, se pretende uma avaliação certificada, deverá contactar um Perito Avaliador     certificado pela CMVM - Comissão de Mercado de Valores Mobiliários.     Ao subscrever os serviços disponibilizados por esta plataforma, e consequente consentimento, a ${COMPANY_NAME} procede à recolha e ao tratamento dos dados pessoais necessários à prestação dos serviços de mediação imobiliária e a fornecer informação sobre bens imobiliários e oportunidades de negócios, designadamente através de ações de informação e marketing. A ${COMPANY_NAME} irá transmitir os dados recolhidos aos consultores imobiliários que compõem a sua rede, exclusivamente para os fins mencionados. Nos termos da legislação em vigor, a nossa empresa compromete-se a garantir a proteção, a confidencialidade e a segurança dos dados pessoais fornecidos. Os titulares dos dados podem a todo o tempo, aceder e/ou solicitar a retificação ou eliminação dos seus dados pessoais, devendo para o efeito dirigir o pedido por escrito. Os dados ora recolhidos serão conservados pelo período de tempo estritamente necessário ao cumprimento dos fins que justificam a sua recolha e apenas pelo período de duração do registo do Subscritor.`,
  },
  en: {
    agent: "Your agent:",
    agency: "Your agency:",
    content: {
      title: "WITH YOU EVERY STEP OF THE WAY",
      subTitle: "GET A FREE VALUATION",
    },
    disclaimer: `The use of this platform is free and intends to provide an expected sale value of a specific property, based on a statistical analysis. The results generated are estimates and provide merely indicative values, obtained from the statistical analysis of real estate databases and other real estate market indicators. Accordingly, ${COMPANY_NAME} does not guarantee any type of compensation if the estimated values ​​are not reached. There are other aspects that determine the economic value of a property, so if you want a certified appraisal, you should contact an Appraisal Expert certified by the CMVM - Portuguese Securities Market Commission. By subscribing to the services provided by this platform, and consequently consenting, ${COMPANY_NAME} collects and processes the personal data necessary for the provision of real estate mediation services and provides information on real estate and business opportunities, namely through actions information and marketing. ${COMPANY_NAME} will transmit the collected data to the real estate consultants that make up its network, exclusively for the aforementioned purposes. Under current legislation, our company is committed to ensuring the protection, confidentiality and security of the personal data provided. Data holders may, at any time, access and/or request the rectification or deletion of their personal data, and for this purpose must address the request in writing. The data collected herein will be kept for the period of time strictly necessary to fulfill the purposes that justify its collection and only for the duration of the Subscriber's registration.`,
  },
};
